/*@import "~bootstrap/dist/css/bootstrap.min.css";*/

/*@import "assets/css/style.css";*/

.loading-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1500;
  top: 0;
  left: 0;
}

.loading-wrap div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 151;
}

.blind {
  display: block;
  overflow: hidden;
  width: 1px;
  height: 1px;
  border: none;
}

/* login */
.lms-login {
  display: flex;
  align-items: center;
  min-height: 300px;
}

.lms-login-wrap {
  display: flex;
  max-width: 960px;
  width: 100%;
  margin: 0rem auto;
  box-shadow: 0px 0px 2.6rem rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  background: #fff;
}

.lms-login-wrap > section {
  width: 50%;
}

.lms-login-wrap .cover-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.login-page img {
  width: 100%;
  /* max-width: 24rem; */
  width: 49%;
  min-width: 60%;
  margin-bottom: 12%;
}

.login-page h3 {
  width: 100%;
  font-weight: 500;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #555;
}

.login-page > p {
  margin-bottom: 7%;
  width: 100%;
  font-weight: 500;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #555;
}

.login-box {
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding-left: 1.8rem;
  background: #fafafa;
  border: none;
  border-left: 0.4rem solid #283f45;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555;
}

/* .login-box::-webkit-input-placeholder {
  color: #666;
}
.login-box:-ms-input-placeholder {
  color: #666;
} */
.login-box::placeholder {
  color: #666;
}

.login-checkbox {
  display: flex;
  align-items: center;
  width: 100%;
}

input#save-id {
  border: 1px solid #ccc;
  margin-right: 0.6rem;
}

.login-checkbox label {
  font-size: 14px;
  line-height: 20px;
  color: #555;
}

.login-page > button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 37rem;
  width: 100%;
  height: 50px;
  min-height: 10%;
  margin: 7% 0;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background: #283f45;
  border-radius: 5rem;
}

.find-password {
  display: flex;
}

.find-password p {
  margin-right: 30px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555;
}

.find-password p:last-of-type {
  position: relative;
  margin-right: 0;
}

.find-password p:last-of-type::after {
  position: absolute;
  top: calc(50% + 1px);
  left: -16px;
  display: inline-block;
  width: 1px;
  height: 10px;
  transform: translateY(-50%);
  content: '';
  background-color: #ccc;
}

@media (max-width: 1024px) {
  .lms-login-wrap {
    flex-direction: column;
  }
  .lms-login-wrap > section {
    width: auto;
  }
  .lms-login-wrap > section:first-child {
    display: none;
  }
}

.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.rcs-custom-scroll .rcs-inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}

.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.btn-file-del2 {
  width: 20px;
  height: 20px;
  padding-right: 1.2rem;
  vertical-align: top;
  border: 0;
  font-size: 0;
  background: url(http://211.55.64.43:9040/SKKU_IMBA/lmsAdmin/assets/images/btn-file-del.png) no-repeat 50%/ 20px;
}
.visual-box .input-box {
  text-align: center;
}

.alarm-count {
  display: block;
  position: absolute;
  top: -3px;
  right: 2px;
  width: 15px;
  height: 15px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  border-radius: 100%;
  background: #00457c;
}

.form-check-input.is_app:checked {
  background-color: #009000;
  border-color: #009000;
}

.form-check-input.is_zoom:checked {
  background-color: #cc00cc;
  border-color: #cc00cc;
}

.disabled-link {
  pointer-events: none;
}
